import { Route, Routes } from 'react-router-dom';

import { NotFound404 } from 'lynkco-up-core';

import routes from './routesDefinitions';
import { RouteGuard, Layout } from '../components';
import {
  Applications,
  Bookings,
  Gdpr,
  Home,
  Login,
  Pricing,
  Availabilities,
  Users,
  Export,
  ServiceFees,
  HistoryLog,
  BookingRequestErrors,
  Colab,
  UpdateManagement,
} from '../modules';
import { AccessDenied } from '../components';
import { useUserContext } from '../modules/Users/UserContext';
import HomePage from '../parts/SendBird/components/home';
import EnergyFees from '../modules/ServiceFees/energyFees';

function AppRoutes() {
  function hasAccess(scope: string) {
    const { checkAccess } = useUserContext();
    return checkAccess(scope);
  }
  return (
    <Routes>
      <Route
        path={routes.home.path}
        element={
          <RouteGuard>
            <Layout />
          </RouteGuard>
        }>
        <Route index element={<Home />}></Route>
        {routes.applications.isAvailable && <Route path={routes.applications.path} element={<Applications />}></Route>}
        {routes.bookings.isAvailable && <Route path={routes.bookings.path} element={<Bookings />}></Route>}
        {routes.gdpr.isAvailable && <Route path={routes.gdpr.path} element={<Gdpr />}></Route>}
        {routes.pricing.isAvailable && <Route path={routes.pricing.path} element={<Pricing />}></Route>}
        {routes.servicefees.isAvailable && hasAccess('EDIT_SERVICE_FEE') && (
          <Route path={routes.servicefees.path} element={<ServiceFees />}></Route>
        )}
        {routes.energyfees.isAvailable && hasAccess('EDIT_SERVICE_FEE') && (
          <Route path={routes.energyfees.path} element={<EnergyFees />}></Route>
        )}
        {routes.historylog.isAvailable && hasAccess('EDIT_SERVICE_FEE') && (
          <Route path={routes.historylog.path} element={<HistoryLog />}></Route>
        )}

        {routes.availabilities.isAvailable && (
          <Route path={routes.availabilities.path} element={<Availabilities />}></Route>
        )}
        {routes.users.isAvailable && hasAccess('VIEW_MANAGEMENT') && (
          <Route path={routes.users.path} element={<Users />}></Route>
        )}
        {routes.export.isAvailable && hasAccess('VIEW_MANAGEMENT') && (
          <Route path={routes.export.path} element={<Export />}></Route>
        )}
        {routes.bookingrequesterror.isAvailable && hasAccess('VIEW_BOOKINGS') && (
          <Route path={routes.bookingrequesterror.path} element={<BookingRequestErrors />}></Route>
        )}
        {routes.bookingrequesterror.isAvailable && hasAccess('VIEW_SENDBIRD') && (
          <Route path={routes.sendbirdreports.path} element={<HomePage />}></Route>
        )}
        {routes.colab.isAvailable && hasAccess('VIEW_COLAB') && (
          <Route path={routes.colab.path} element={<Colab />}></Route>
        )}
        {routes.updateManagement.isAvailable && hasAccess('VIEW_COLAB') && (
          <Route path={routes.updateManagement.path} element={<UpdateManagement />}></Route>
        )}
      </Route>
      <Route path={routes.login.path} element={<Login />}></Route>
      <Route path={routes.accessDenied.path} element={<AccessDenied />}></Route>
      <Route path="*" element={<NotFound404 />}></Route>
    </Routes>
  );
}

export { routes };
export default AppRoutes;
