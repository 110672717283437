import dayjs from 'dayjs';

import { Button, DropdownDivider, DropdownItem, Utils } from 'lynkco-up-core';
import { GenericFunction } from 'lynkco-up-core/dist/types/types';

import { sharedConfig } from '../../config';
import { updateBookings } from '../../services/bookings';
import { Booking, Market, Status } from '../../services/bookings/types';
import { formatDate } from '../shared/utils';
import { TerminationText } from './types';

const cancelableStatuses = [Status.ACCEPTED, Status.REQUESTED];
export const endableStatuses = [Status.ACTIVE_BY_BORROWER, Status.ACTIVE_BY_START];
export const acceptedBookingTerminationStatuses = [...cancelableStatuses, ...endableStatuses];

export const generateFormattedBookingDetails = (booking: Booking) =>
  `
LENDER: 
Customer ID: [${booking.lender.snowflakeId}] 
Name: [${booking.lender.firstName}]


BORROWER: 
Customer ID: [${booking.borrower.snowflakeId}] 
Name: [${booking.borrower.firstName}] 


BOOKING: 
Order reference: [${booking.orderReference}]] 
Booking ID: [${booking.id}] 


Booking start: [${formatDate(booking.start)}]

Booking end: [${formatDate(booking.end)}]

Booking actual end: [${formatDate(booking.actualEnd)}]


CAR: 
VIN [${booking.vehicle.vin}] 
Registration number: [${booking.vehicle.regNr}]
`;

export const bookingStatusNames = {
  [Status.ACCEPTED]: 'Accepted',
  [Status.ACTIVE_BY_BORROWER]: 'Car picked up',
  [Status.ACTIVE_BY_START]: 'Booking time started',
  [Status.CANCELLED_BY_BORROWER]: 'Cancelled by borrower',
  [Status.CANCELLED_BY_EC]: 'Cancelled by support',
  [Status.CANCELLED_BY_ERROR]: 'Cancelled by error',
  [Status.CANCELLED_BY_LENDER]: 'Cancelled by lender',
  [Status.CANCELLED_BY_PSP]: 'Cancelled by PSP',
  [Status.CANCELLED_BY_TIMEOUT]: 'Cancelled by timeout',
  [Status.CANCELLED]: 'Cancelled',
  [Status.CLOSED]: 'Closed',
  [Status.FINISHED]: 'Car returned',
  [Status.REJECTED]: 'Rejected',
  [Status.REQUESTED]: 'Requested',
};

export function getBookingEndedMessage(booking: Booking) {
  const isReturned = isReturnedBooking(booking);
  const dateToSubstractFrom = isReturned ? booking.actualEnd : dayjs().toString();
  const endTime = getBookingEndTime(booking);
  const timeDifference = getTimeDifference(dateToSubstractFrom, endTime);
  const timeDifferenceMessage = getTimeDifferenceText(timeDifference);
  const isLateReturn = isLateReturnBooking(booking);

  const bookingEndedMessage = isReturned
    ? isLateReturn
      ? `Booking Ended : ${timeDifferenceMessage} late`
      : `Booking Ended : ${timeDifferenceMessage.trim() ? `${timeDifferenceMessage} early` : `on time`}`
    : `Late booking : ${timeDifferenceMessage} overtime`;

  return bookingEndedMessage;
}

export function isOngoingBooking(booking: Booking) {
  return [Status.ACTIVE_BY_START, Status.ACTIVE_BY_BORROWER].includes(booking.status);
}

export function isReturnedBooking(booking: Booking) {
  return [Status.FINISHED, Status.CLOSED].includes(booking.status);
}

export function isExtendedBooking(booking: Booking) {
  return booking.extension?.status === Status.ACCEPTED;
}

export function isOvertimeBooking(booking: Booking) {
  const isOngoing = isOngoingBooking(booking);

  if (!isOngoing) {
    return false;
  }

  const startDate = dayjs().toString();
  const endDate = booking.extension?.endTime || booking.end;
  const timeDifference = getTimeDifference(startDate, endDate);

  return timeDifference > 0;
}

export function isInTheProblemTimespan(booking: Booking) {
  const problemStartDate = new Date(2024, 10, 27);
  const problemEndDate = new Date(2025, 0, 14);
  return new Date(booking.end) > problemStartDate && new Date(booking.end) < problemEndDate;
}
export function isInTheRivertyProblemTimespan(booking: Booking) {
  const problemStartDate = new Date(2024, 11, 16);
  const problemEndDate = new Date(2025, 1, 25);
  return new Date(booking.end) > problemStartDate && new Date(booking.end) < problemEndDate;
}
export function isLateReturnBooking(booking: Booking) {
  const timeDifference = getTimeDifference(booking.actualEnd, booking.end);
  const isReturned = isReturnedBooking(booking);

  return timeDifference > 1 && isReturned;
}

export function getBookingEndTime(booking: Booking) {
  return booking.extension?.status === Status.ACCEPTED ? booking.extension.endTime : booking.end;
}

export function getTimeDifference(startDate: string | null, endDate: string) {
  const firstDate = dayjs(startDate);
  const secondDate = dayjs(endDate);
  const timeDifference = firstDate.diff(secondDate, 'minute');

  return timeDifference;
}

export function getTimeDifferenceText(totalMinutes: number) {
  let minutesValue = Math.abs(totalMinutes);
  let daysValue = 0;
  let hoursValue = 0;
  if (minutesValue > 60) {
    if (minutesValue > 1440) {
      daysValue = Math.floor(minutesValue / 1440);
      minutesValue %= 1440;
    }
    hoursValue = Math.floor(minutesValue / 60);
    minutesValue = Math.floor(minutesValue % 60);
  }
  const days = daysValue > 0 ? `${daysValue} d ` : '';
  const hours = hoursValue > 0 ? `${hoursValue} h ` : '';
  const minutes = minutesValue > 0 ? `${minutesValue} m` : '';

  return `${days}${hours}${minutes}`;
}

export function getReturnTimeDelta(booking: Booking) {
  const isReturned = isReturnedBooking(booking);
  return isReturned ? getBookingEndedMessage(booking).split(':')[1] : '-';
}

export function getBookingTerminationText(booking: Booking | null) {
  if (!booking) return '';

  return endableStatuses.includes(booking.status) ? TerminationText.End : TerminationText.Cancel;
}

type BookingTerminationResult = [Booking[] | null, unknown | null];
export async function terminateBooking(
  booking: Booking | null,
  onSuccess: (text: string) => void,
  onError: GenericFunction,
  forceEnd = false,
  cancelPayment = false,
): Promise<BookingTerminationResult> {
  try {
    if (!booking) {
      throw new Error('Booking termination failed, selected booking was null.');
    }

    const text = getBookingTerminationText(booking);
    let status: Status.CANCELLED | Status.FINISHED = Status.CANCELLED;

    if (endableStatuses.includes(booking.status)) {
      status = Status.FINISHED;
    }

    /** forceEnd set to `false` by default */
    const result = await updateBookings([booking], status, forceEnd, cancelPayment);
    onSuccess?.(text);
    return [result, null];
  } catch (error) {
    onError?.();
    return [null, error];
  }
}

type TerminateActionProps = {
  booking: Booking;
  onClick: (booking: Booking) => void;
  type: 'menuItem' | 'button';
};

/**
 * Renders a termination action button for a given booking.
 */
export function TerminateAction({ booking, onClick, type = 'menuItem' }: TerminateActionProps): JSX.Element | null {
  /** If the status in not included it means the booking cannot be terminated, so nothing is rendered */
  if (!acceptedBookingTerminationStatuses.includes(booking.status)) {
    return null;
  }

  const text = Utils.capitalizeText(getBookingTerminationText(booking));

  const templates = {
    menuItem: (
      <>
        <DropdownDivider />
        <DropdownItem onClick={() => onClick(booking)}>
          <div className="uppercase text-center font-semibold text-danger-500 p-2 rounded">
            <span>{text} booking</span>
          </div>
        </DropdownItem>
      </>
    ),
    button: (
      <Button variant="danger" onClick={() => onClick(booking)}>
        {text} booking
      </Button>
    ),
  };

  return templates[type];
}

export function buildStripeUrl(market: Market, orderReference: string) {
  const stripeId = sharedConfig.stripe;

  if (!market || !stripeId) return;

  const marketCode = market.toLowerCase();
  const regExp = new RegExp(`${marketCode}.{13}`, 'g');
  const match = stripeId.match(regExp);

  if (match?.length !== 1) return;

  const stripeMarketId = match[0].replace(marketCode, 'acct_1Ks');

  return `https://dashboard.stripe.com/${stripeMarketId}/search?query=${orderReference}`;
}
