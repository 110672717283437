import { Button, Icon, SlideOver, Table, Tbody, Td, Th, Thead, Tr } from 'lynkco-up-core';
import { useEffect, useState } from 'react';
import { LoadingMask } from '../../components';
import { formatDateOnly } from '../../parts/shared/utils';
import { ColabQuery, ColabRecords, GetColab } from './types';
import { getColabs } from './getColab';
import { ColabDetails } from '../../parts/Colab';
import SortCell from '../../components/SortCell';
import ColabFilters from '../../parts/Colab/colabFilters';
import dayjs from 'dayjs';
import ColabStatusCell from '../../parts/Colab/colabStatusCell';
import { convertToExcel } from '../../parts/Colab/convertToExcel';

const dateFormat = 'YYYY-MM-DDTHH:mm:ss.SSSZ';
const defaultStart = dayjs().subtract(100, 'day').startOf('day').format(dateFormat);
const defaultEnd = dayjs().endOf('day').format(dateFormat);
let updatedStartDate = '';
let updatedEndDate = '';

function ColabsTable() {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<ColabRecords>();
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(20);
  const pageCount = Math.ceil((data?.entities?.length || 0) / perPage);
  const startIndex = (currentPage - 1) * perPage;
  const endIndex = startIndex + perPage;
  const [detailsIsOpen, setDetailsIsOpen] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState<GetColab>();
  const [sortIconValue, setSortIconValue] = useState(1);

  const paginatedRecords = data?.entities.slice(startIndex, endIndex) || [];

  const [query, setQuery] = useState<ColabQuery>({
    startDate: defaultStart,
    endDate: defaultEnd,
  });

  const fillerArray = new Array(5).fill(0).map(() => Math.random());

  const onSortIconValueChange = () => {
    setSortIconValue(s => -s);
    sortRecordsByDate(sortIconValue);
  };

  async function fetchData() {
    try {
      setIsLoading(true);
      const colabs = await getColabs(query);
      setData(colabs);
    } catch (error) {
      console.error('Error occurred while fetching colab records: ', error);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
  }, [query]);

  function sortRecordsByDate(value: number) {
    if (value === 1) {
      return data?.entities.sort((a: GetColab, b: GetColab) => a.timestamp.localeCompare(b.timestamp));
    } else {
      return data?.entities.sort((a: GetColab, b: GetColab) => b.timestamp.localeCompare(a.timestamp));
    }
  }

  function handleOpenColabDetails(entity: GetColab) {
    setSelectedRecord(entity);
    setDetailsIsOpen(true);
  }

  function handleUpdateFilters(startDate: string, endDate: string, vehicleModel: string) {
    updatedStartDate = startDate;
    updatedEndDate = endDate;
    setQuery(prevQuery => ({
      ...prevQuery,
      startDate: updatedStartDate,
      endDate: updatedEndDate,
      vehicleModel: vehicleModel,
    }));
  }

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const goToPreviousPage = () => {
    if (currentPage > 1) {
      handlePageChange(currentPage - 1);
    }
  };

  const goToNextPage = () => {
    if (currentPage < pageCount) {
      handlePageChange(currentPage + 1);
    }
  };

  function handleIndex() {
    if (currentPage > pageCount) setCurrentPage(1);
    setPerPage(20);
  }

  useEffect(() => {
    handleIndex();
  }, [data]);

  function handlePerPageSelectChange(value: string): void {
    setPerPage(Number(value));
    setCurrentPage(1);
  }

  function carModelValueHandler(value: string) {
    return value === 'CX11' ? 'CX11 A1' : value;
  }

  function toExcel() {
    if (data?.entities) convertToExcel(data.entities);
  }

  return (
    <div className="z-10 availabilities-table mb-72">
      <Button onClick={toExcel} variant="transparent" extraClasses="mt-2 mr-2 float-right">
        Export to Excel
      </Button>
      <ColabFilters updateFilters={handleUpdateFilters} records={data?.entities.length} />
      <div className="overflow-x-scroll">
        <Table extraClasses="min-w-max">
          <Thead extraClasses="!bg-neutral-50">
            {isLoading ? (
              <Tr>
                <Th>
                  <div className="pl-6 py-1.5">
                    <LoadingMask />
                  </div>
                </Th>
                <Th>
                  <LoadingMask />
                </Th>
                <Th>
                  <LoadingMask />
                </Th>
                <Th>
                  <LoadingMask />
                </Th>
                <Th>
                  <LoadingMask />
                </Th>
                <Th>
                  <LoadingMask />
                </Th>
              </Tr>
            ) : (
              <Tr>
                <Th extraClasses="!p-0 hover:bg-gray-200">
                  <span className="font-medium text-sm text-neutral-400 flex justify-start ml-6">
                    <SortCell sortValue={sortIconValue} sortLabel="Date" onClick={onSortIconValueChange} />
                  </span>
                </Th>
                <Th>
                  <span className="font-medium text-sm text-neutral-400 flex justify-start">CATEGORY</span>
                </Th>
                <Th>
                  <span className="font-medium text-sm text-neutral-400 flex justify-start">MARKET</span>
                </Th>
                <Th>
                  <span className="font-medium text-sm text-neutral-400 flex justify-start">TRANSLATED FEEDBACK</span>
                </Th>
                <Th>
                  <span className="font-medium text-sm text-neutral-400 flex items-center justify-start ml-6">
                    CAR MODEL
                  </span>
                </Th>
                <Th>
                  <span className="font-medium text-sm text-neutral-400 flex justify-start">APP COMING FROM</span>
                </Th>
                <Th>
                  <span className="font-medium text-sm text-neutral-400 flex justify-start">STATUS</span>
                </Th>
                <Th>
                  <span></span>
                </Th>
              </Tr>
            )}
          </Thead>
          <Tbody>
            {isLoading ? (
              fillerArray.map(_ => (
                <Tr key={_}>
                  <Td>
                    <div className="pl-5 py-3">
                      <LoadingMask />
                    </div>
                  </Td>
                  <Td>
                    <LoadingMask />
                  </Td>
                  <Td>
                    <LoadingMask />
                  </Td>
                  <Td>
                    <LoadingMask />
                  </Td>
                  <Td>
                    <LoadingMask />
                  </Td>
                  <Td cellWidth={100} align="right">
                    <LoadingMask />
                  </Td>
                </Tr>
              ))
            ) : paginatedRecords ? (
              paginatedRecords.map((entity, index) => {
                const rowColourIntensity = index % 2 === 0 ? '50' : '0';
                return (
                  <Tr key={index} extraClasses={`bg-primary-${rowColourIntensity}`}>
                    <Td>
                      <span className="font-medium pl-6 text-black flex justify-start">
                        {formatDateOnly(entity.timestamp)}
                      </span>
                    </Td>
                    <Td>
                      <div className="px-6 py-2 text-sm text-neutral-800">{entity.category}</div>
                    </Td>
                    <Td>
                      <div className="px-6 py-2 text-sm text-neutral-800">{entity.vehicleMarket}</div>
                    </Td>
                    <Td>
                      <div className="px-6 py-2 text-sm text-neutral-800 max-w-[400px]">{entity.translated}</div>
                    </Td>
                    <Td>
                      <div className="px-6 py-2 text-sm text-neutral-800">
                        {carModelValueHandler(entity.vehicleModel)}
                      </div>
                    </Td>
                    <Td>
                      <div className="px-6 py-2 text-sm text-neutral-800">{entity.fromAppName}</div>
                    </Td>
                    <Td>
                      <div>
                        <ColabStatusCell colabEntity={entity} />
                      </div>
                    </Td>
                    <Td cellWidth={100} align="right">
                      <div className="flex items-center justify-end pr-2">
                        <Button
                          onClick={() => handleOpenColabDetails(entity)}
                          variant="transparent"
                          size="small"
                          extraClasses="mr-2">
                          <Icon name="list_alt" size="small" variant="gray" />
                        </Button>
                      </div>
                    </Td>
                  </Tr>
                );
              })
            ) : (
              <p>Loading Colab records</p>
            )}
          </Tbody>
        </Table>
      </div>
      <div className="mt-4 flex justify-between p-6 border-t">
        <Button
          onClick={goToPreviousPage}
          extraClasses={`px-3 py-2 mx-1 rounded-md ${
            currentPage === pageCount ? 'bg-gray-300 text-gray-600' : 'bg-blue-500 text-white'
          }`}
          isDisabled={currentPage === 1}
          variant="white">
          Previous Page
        </Button>
        <div>
          <label htmlFor="perPageSelect" className="text-gray-500">
            Records per page:{' '}
          </label>
          <select
            id="perPageSelect"
            className="w-16  p-2 border rounded-md bg-white"
            value={perPage}
            onChange={e => handlePerPageSelectChange(e.target.value)}>
            <option value={20}>20</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
            <option value={data?.entities.length}>all</option>
          </select>
        </div>
        <div className="text-gray-500">
          Page {currentPage} of {pageCount}
        </div>
        <Button
          onClick={goToNextPage}
          extraClasses={`px-3 py-2 mx-1 rounded-md ${
            currentPage === pageCount ? 'bg-gray-300 text-gray-600' : 'bg-blue-500 text-white'
          }`}
          isDisabled={currentPage === pageCount}
          variant="white">
          Next Page
        </Button>
      </div>
      <SlideOver isOpen={detailsIsOpen} onCancel={() => setDetailsIsOpen(false)}>
        <ColabDetails colabEntity={selectedRecord}></ColabDetails>
      </SlideOver>
    </div>
  );
}

export default ColabsTable;
