export const userRoles = [
  {
    label: '1st Line',
    value: 'firstline',
  },
  {
    label: '2nd Line',
    value: 'secondline',
  },
  {
    label: 'Team Lead/Trainer',
    value: 'teamlead',
  },
  {
    label: 'Admin',
    value: 'administrator',
  },
  {
    label: 'Colab User - Restricted access',
    value: 'colabUserRestrictedAccess',
  },
  {
    label: 'Colab User - Full access',
    value: 'colabUserFullAccess',
  },
];

export function mapValuesToLabels(value: string) {
  const role = userRoles.find(role => role.value.toLowerCase() === value.toLowerCase());
  return role ? role.label : value;
}
