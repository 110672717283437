import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  Card,
  CardContent,
  Typography,
  IconButton,
  Collapse,
  Divider,
  Box,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';
import { Booking, GetMarketInfo, ServiceFee } from '../../services/bookings/types';
import { Alert, Table } from 'lynkco-up-core';
import { getMarket } from '../../services/markets/getMarkets';
import getTelemetry from '../../services/bookings/telemetry/getTelemetry';
import { TelemetryData } from './types';
import { durations } from '../shared/utils';

type CostCalculationsProps = {
  booking: Booking;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ExpandMore = styled((props: any) => {
  const { ...other } = props;
  return <IconButton {...other} />;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
})(({ theme, expand }: any) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

function getDaysAndHours(startDateStr: string, endDateStr: string): { days: number; hours: number } {
  const startDate = new Date(startDateStr);
  const endDate = new Date(endDateStr);

  const diffInMilliseconds = endDate.getTime() - startDate.getTime();
  const diffInHours = diffInMilliseconds / (1000 * 60 * 60);
  let hours = Math.ceil(diffInHours % 24);
  let days = (diffInHours - hours) / 24;
  if (hours > 6) {
    days = days + 1;
    hours = 0;
  }
  return { days: days >= 1 ? days : 0, hours };
}

const BookingDetailCostCalculations: React.FC<CostCalculationsProps> = ({ booking }) => {
  const marketRef = useRef<GetMarketInfo>();
  const telemetryRef = useRef<TelemetryData>();
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    async function fetchData() {
      try {
        const [fetchedMarketInfo, fetchedTelemetry] = await Promise.all([
          getMarket(booking.vehicle.market),
          getTelemetry(booking.id),
        ]);
        marketRef.current = fetchedMarketInfo;
        telemetryRef.current = fetchedTelemetry;
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    }
    fetchData();
  }, []);
  const serviceFee = (): ServiceFee | undefined => {
    const priceLevel = 'FEE_PRICE_LEVEL_2';
    if (days === 0 || days === 1 || hours === 24) {
      if (hours > 0 && hours <= 6) {
        return marketRef.current?.serviceFees.find(
          item => item.priceLevel === priceLevel && item.duration === 'FEE_DURATION_0_H_TO_6_H',
        );
      } else if (hours >= 7 && hours <= 24) {
        return marketRef.current?.serviceFees.find(
          item => item.priceLevel === priceLevel && item.duration === 'FEE_DURATION_6_H_TO_24_H',
        );
      }
    } else if (days >= 1 && days <= 5) {
      return marketRef.current?.serviceFees.find(
        item => item.priceLevel === priceLevel && item.duration === 'FEE_DURATION_24_H_TO_5_DAYS',
      );
    } else if (days > 5 && days <= 10) {
      return marketRef.current?.serviceFees.find(
        item => item.priceLevel === priceLevel && item.duration === 'FEE_DURATION_5_DAYS_TO_10_DAYS',
      );
    } else if (days > 10 && days <= 28) {
      return marketRef.current?.serviceFees.find(
        item => item.priceLevel === priceLevel && item.duration === 'FEE_DURATION_10_DAYS_TO_28_DAYS',
      );
    }
  };
  const [marketFeeOpen, setMarketFeeOpen] = useState(false);
  const [serviceFeeOpen, setServiceFeeOpen] = useState(false);
  const [fuelOpen, setFuelOpen] = useState(false);
  const [electricOpen, setElectricOpen] = useState(false);
  const [guestOpen, setGuestOpen] = useState(false);
  const { days, hours } = useMemo(() => {
    return getDaysAndHours(booking.start, booking.end);
  }, []);
  const feeData = serviceFee();

  if (isLoading) {
    return <div>Loading...</div>; // Don't render until loaded
  }
  return (
    <>
      <Alert
        type={'error'}
        heading={'New Cost Calculation'}
        body={
          'This tab for cost calculation is new. If you see any strange numbers or calculations do not add up please create Ivanti ticket in Connectivity Lynk & Co Car Sharing Back-end '
        }
      />
      <Divider sx={{ my: 2 }} />

      <Card variant="outlined" sx={{ maxWidth: 450, margin: 'auto', p: 3, borderRadius: 3, boxShadow: 3 }}>
        <CardContent>
          <Typography variant="h5" gutterBottom textAlign="center" fontWeight="bold">
            Booking Cost
          </Typography>
          <Divider sx={{ my: 2 }} />
          <Typography variant="body1">Number of Days: {days}</Typography>
          <Typography variant="body1">Number of Hours: {hours}</Typography>
          <Typography variant="body1" fontWeight="bold">
            Buffer: {marketRef.current?.buffer}
            {marketRef.current?.currency}
          </Typography>
          <Divider sx={{ my: 2 }} />
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Typography variant="body1" fontWeight="bold">
              Lender Setup Price: {booking.cost.base.toFixed(2) + marketRef.current?.currency}
            </Typography>
            <ExpandMore expand={marketFeeOpen} onClick={() => setMarketFeeOpen(!marketFeeOpen)}>
              <ExpandMoreIcon />
            </ExpandMore>
          </Box>
          <Collapse in={marketFeeOpen} timeout="auto" unmountOnExit>
            <Box p={2} bgcolor="grey.100" borderRadius={2}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <strong>Type</strong>
                    </TableCell>
                    <TableCell align="right">
                      <strong>Rate</strong>
                    </TableCell>
                    <TableCell align="right">
                      <strong>Quantity</strong>
                    </TableCell>
                    <TableCell align="right">
                      <strong>Total</strong>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>Daily Market Price</TableCell>
                    <TableCell align="right">
                      {booking.vehicle.price.daily}
                      {marketRef.current?.currency}
                    </TableCell>
                    <TableCell align="right">{days} days</TableCell>
                    <TableCell align="right">
                      {(booking.vehicle.price.daily * days).toFixed(2)}
                      {marketRef.current?.currency}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Hourly Market Price</TableCell>
                    <TableCell align="right">
                      {booking.vehicle.price.hourly}
                      {marketRef.current?.currency}
                    </TableCell>
                    <TableCell align="right">{hours} hours</TableCell>
                    <TableCell align="right">
                      {(booking.vehicle.price.hourly * hours).toFixed(2)}
                      {marketRef.current?.currency}
                    </TableCell>
                  </TableRow>
                  <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell colSpan={3}>
                      <strong>Total</strong>
                    </TableCell>
                    <TableCell align="right">
                      <strong>
                        {(booking.vehicle.price.daily * days + booking.vehicle.price.hourly * hours).toFixed(2)}
                        {marketRef.current?.currency}
                      </strong>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>

          <Divider sx={{ my: 2 }} />

          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Typography variant="body1" fontWeight="bold">
              Service Fee: {booking.cost.service.toFixed(2) + marketRef.current?.currency}
            </Typography>
            <ExpandMore expand={serviceFeeOpen} onClick={() => setServiceFeeOpen(!serviceFeeOpen)}>
              <ExpandMoreIcon />
            </ExpandMore>
          </Box>
          <Collapse in={serviceFeeOpen} timeout="auto" unmountOnExit>
            <Box p={1} bgcolor="grey.100" borderRadius={2}>
              <Table size="small">
                <TableBody>
                  <TableRow>
                    <TableCell>Service fee group</TableCell>
                    <TableCell align="right">
                      {durations.map(i => {
                        if (i.durationKey === feeData?.duration) {
                          return i.title;
                        }
                      })}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Hourly/Daily Service Price</TableCell>
                    <TableCell align="right">{feeData?.fee}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>days</TableCell>
                    <TableCell align="right">{days}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>hours</TableCell>
                    <TableCell align="right">{hours}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>VAT {marketRef.current?.vat}%</TableCell>
                    <TableCell align="right">
                      {(booking.cost.service * (marketRef.current?.vat || 0)) / (100 + (marketRef.current?.vat || 0))}
                    </TableCell>
                  </TableRow>
                  <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell>
                      <strong>Total</strong>
                    </TableCell>
                    <TableCell align="right">
                      <strong>
                        {booking.cost.service}
                        {' ' + marketRef.current?.currency}
                      </strong>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
          <Divider sx={{ my: 2 }} />
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Typography variant="body1" fontWeight="bold">
              Fuel Fee: {booking.cost.fuel.toFixed(2) + marketRef.current?.currency}
            </Typography>
            <ExpandMore expand={fuelOpen} onClick={() => setFuelOpen(!fuelOpen)}>
              <ExpandMoreIcon />
            </ExpandMore>
          </Box>
          <Collapse in={fuelOpen} timeout="auto" unmountOnExit>
            <Box p={1} bgcolor="grey.100" borderRadius={2}>
              <Table>
                <TableRow>
                  <TableCell>Start Fuel Amount</TableCell>
                  <TableCell align="right">{telemetryRef.current?.fuelEntries[0]?.value ?? 'Not provided'}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>End Fuel Amount</TableCell>
                  <TableCell align="right">
                    {telemetryRef.current?.fuelEntries[telemetryRef.current?.fuelEntries.length - 1]?.value ??
                      'Not provided'}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Fuel Per Liter</TableCell>
                  <TableCell align="right">
                    {booking.cost.fuelPerLiter.toFixed(2) + marketRef.current?.currency}
                  </TableCell>
                </TableRow>
                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell>
                    <strong>Total</strong>
                  </TableCell>
                  <TableCell align="right">
                    <strong>{booking.cost.fuel.toFixed(2) + (marketRef.current?.currency ?? '')}</strong>
                  </TableCell>
                </TableRow>
              </Table>
            </Box>
          </Collapse>
          <Divider sx={{ my: 2 }} />
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Typography variant="body1" fontWeight="bold">
              Electricity Fee: {booking.cost.electric.toFixed(2) + marketRef.current?.currency}
            </Typography>
            <ExpandMore expand={electricOpen} onClick={() => setElectricOpen(!electricOpen)}>
              <ExpandMoreIcon />
            </ExpandMore>
          </Box>
          <Collapse in={electricOpen} timeout="auto" unmountOnExit>
            <Box p={1} bgcolor="grey.100" borderRadius={2}>
              <Table>
                <TableRow>
                  <TableCell>Start Electricity Amount</TableCell>
                  <TableCell align="right">
                    {telemetryRef.current?.electricEntries[0]?.value ?? 'Not provided'}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>End Electricity Amount</TableCell>
                  <TableCell align="right">
                    {telemetryRef.current?.fuelEntries[telemetryRef.current?.electricEntries.length - 1]?.value ??
                      'Not provided'}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Electricity Per Liter</TableCell>
                  <TableCell align="right">
                    {booking.cost.electricPerKwh.toFixed(2) + marketRef.current?.currency}
                  </TableCell>
                </TableRow>
                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell>
                    <strong>Total</strong>
                  </TableCell>
                  <TableCell align="right">
                    <strong>{booking.cost.electric.toFixed(2) + (marketRef.current?.currency ?? '')}</strong>
                  </TableCell>
                </TableRow>
              </Table>
            </Box>
          </Collapse>
          <Divider sx={{ my: 2 }} />
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Typography variant="h6" fontWeight="bold" color="primary">
              Borrower Cost:{' '}
              {(booking.cost.service + booking.cost.base + booking.cost.fuel + booking.cost.electric).toFixed(2)}{' '}
              {marketRef.current?.currency}
            </Typography>
            <ExpandMore expand={guestOpen} onClick={() => setGuestOpen(!guestOpen)}>
              <ExpandMoreIcon />
            </ExpandMore>
          </Box>
          <Collapse in={guestOpen} timeout="auto" unmountOnExit>
            <Box p={1} bgcolor="grey.100" borderRadius={2}>
              <Table>
                <TableRow>
                  <TableCell>
                    <Typography
                      onClick={() => setMarketFeeOpen(true)}
                      sx={{ cursor: 'pointer', textDecoration: 'underline' }}>
                      Market Fee
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      onClick={() => setMarketFeeOpen(true)}
                      sx={{ cursor: 'pointer', textDecoration: 'underline' }}>
                      {booking.cost.base + (marketRef.current?.currency ?? '')}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography
                      onClick={() => setServiceFeeOpen(true)}
                      sx={{ cursor: 'pointer', textDecoration: 'underline' }}>
                      Service Fee
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      onClick={() => setServiceFeeOpen(true)}
                      sx={{ cursor: 'pointer', textDecoration: 'underline' }}>
                      {booking.cost.service + (marketRef.current?.currency ?? '')}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell>
                    <Typography
                      onClick={() => setFuelOpen(true)}
                      sx={{ cursor: 'pointer', textDecoration: 'underline' }}>
                      Fuel Fee
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      onClick={() => setFuelOpen(true)}
                      sx={{ cursor: 'pointer', textDecoration: 'underline' }}>
                      {booking.cost.fuel.toFixed(2) + (marketRef.current?.currency ?? '')}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell>
                    <Typography
                      onClick={() => setElectricOpen(true)}
                      sx={{ cursor: 'pointer', textDecoration: 'underline' }}>
                      Electricity Fee
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      onClick={() => setElectricOpen(true)}
                      sx={{ cursor: 'pointer', textDecoration: 'underline' }}>
                      {booking.cost.electric.toFixed(2) + (marketRef.current?.currency ?? '')}
                    </Typography>
                  </TableCell>
                </TableRow>
              </Table>
            </Box>
            <Divider sx={{ my: 2 }} />
          </Collapse>
          <Typography variant="h6" fontWeight="bold" color="secondary">
            Booking Cost:{' '}
            {booking.cost.base + booking.cost.fuel + booking.cost.electric < 0
              ? 0
              : (booking.cost.base + booking.cost.fuel + booking.cost.electric).toFixed(2)}{' '}
            {marketRef.current?.currency ?? ''}
          </Typography>
          <Typography variant="h6" fontWeight="bold" color="secondary">
            Service Fee: {booking.cost.service + ' ' + (marketRef.current?.currency ?? '')}
          </Typography>
          <Typography variant="h6" fontWeight="bold" color="secondary">
            Refund from buffer:{' '}
            {(booking.cost.fuel > 0
              ? ((marketRef.current?.buffer || 0) - booking.cost.fuel).toFixed(2)
              : marketRef.current?.buffer) +
              ' ' +
              marketRef.current?.currency}
          </Typography>
          <Typography variant="h6" fontWeight="bold" color="secondary">
            Lender gets in money: {booking.cost.lenderEarning.toFixed(2) + ' ' + marketRef.current?.currency}
          </Typography>
          {booking.cost.fuel < 0 ? (
            <Typography variant="h6" fontWeight="bold" color="secondary">
              Lender earning in fuel: {(-1 * booking.cost.fuel).toFixed(2) + ' ' + marketRef.current?.currency}
            </Typography>
          ) : null}
          {booking.cost.electric < 0 ? (
            <Typography variant="h6" fontWeight="bold" color="secondary">
              Lender earning in electricity:{' '}
              {(-1 * booking.cost.electric).toFixed(2) + ' ' + marketRef.current?.currency}
            </Typography>
          ) : null}
        </CardContent>
      </Card>
    </>
  );
};

export default BookingDetailCostCalculations;
