import { Tab } from 'lynkco-up-core/dist/types/components/Tabs';
import { Tabs } from 'lynkco-up-core';
import { GetColab } from '../../modules/Colab/types';
import ColabDetailsOverview from './colabDetailsOverview';
import ColabComment from './colabComment';

type ColabRecordProps = { colabEntity?: GetColab };

function ColabDetails({ colabEntity }: ColabRecordProps) {
  const tabs: Tab[] = [
    {
      name: 'Overview',
      content: <ColabDetailsOverview colabEntity={colabEntity} />,
      isActive: true,
    },
    {
      name: 'Comment',
      content: <ColabComment />,
    },
  ];

  return (
    <div>
      <h2 className="font-medium text-xl mb-2.5">Colab Details</h2>
      <Tabs tabsName="colabRecords-details" tabs={tabs} />
    </div>
  );
}

export default ColabDetails;
