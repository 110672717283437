import { Button } from 'lynkco-up-core';
import React, { useState } from 'react';

function colabComments() {
  const [comment, setComment] = useState('');
  const maxLength = Number(process.env.REACT_APP_COLAB_COMMENT_MAX_LENGTH) || 200;
  const [isMaxLengthExceeded, setIsMaxLengthExceeded] = useState(false);

  function handleSubmit() {
    console.log('comment submitted');
  }

  const handleCommentTyping = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newComment = event.target.value;
    setComment(newComment);
    newComment.length >= maxLength ? setIsMaxLengthExceeded(true) : setIsMaxLengthExceeded(false);
  };

  const remainingCharacters = maxLength - comment.length;

  return (
    <div className="min-h-screen">
      <div className="container mx-auto p-8">
        <h1 className="text-3xl font-semibold mb-4">Comments</h1>
        <form className="mt-8">
          <textarea
            name="comment"
            placeholder="Your Comment"
            className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            value={comment}
            onChange={handleCommentTyping}
            maxLength={maxLength}
            rows={3}
          />
          <div className="flex items-center justify-between text-sm text-gray-500 mt-1">
            <span className={isMaxLengthExceeded ? 'text-red-500' : 'invisible'}>Maximum characters used.</span>
            <span>
              {remainingCharacters}/{maxLength}
            </span>
          </div>
          <Button
            onClick={handleSubmit}
            variant="primary"
            extraClasses="mt-4 px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500">
            Submit Comment
          </Button>
        </form>
      </div>
    </div>
  );
}

export default colabComments;
