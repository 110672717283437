import { PageHeading } from '../../components';

function UpdateManagement() {
  return (
    <div>
      <div className="z-10 bookings-table">
        <title>Update Management</title>
        <PageHeading title="Update Management" description="Update Management showed here"></PageHeading>
      </div>
    </div>
  );
}

export default UpdateManagement;
