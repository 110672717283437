import XLSX from 'xlsx-js-style';
import { GetColab } from '../../modules/Colab/types';

function convertToExcel(data: GetColab[]) {
  const excelData = data.map(
    ({
      timestamp,
      transcribed,
      translated,
      systemLanguage,
      vehicleModel,
      vehicleModelYear,
      vehicleMarket,
      age,
      fromAppName,
      category,
      fromAppVersion,
      dhuVersion,
      reference,
    }) => ({
      Timestamp: timestamp ? timestamp : '',
      Transcribe_Feedback: transcribed ? transcribed : '',
      Translated_Text: translated ? translated : '',
      System_Language: systemLanguage ? systemLanguage : '',
      Car_Model: vehicleModel ? vehicleModel : '',
      Car_Model_Year: vehicleModelYear ? vehicleModelYear : '',
      Market: vehicleMarket ? vehicleMarket : '',
      Age: age ? age : '',
      Coming_From_Which_Version: fromAppName ? fromAppName : '',
      Category: category ? category : '',
      App_Version: fromAppVersion ? fromAppVersion : '',
      HU_SW_Version: dhuVersion ? fromAppVersion : '',
      Comment: reference ? reference : '',
    }),
  );

  excelData.map(i => {
    i.Car_Model === 'CX11' ? (i.Car_Model = 'CX11-A1') : i.Car_Model;
  });

  const headers = Object.keys(excelData[0]);

  const ws = XLSX.utils.json_to_sheet(excelData, { header: headers });

  headers.forEach((header, index) => {
    const cell = ws[XLSX.utils.encode_cell({ r: 0, c: index })];
    if (cell) {
      cell.s = {
        font: {
          color: { rgb: 'FFFFFF' },
          sz: 14,
          bold: true,
        },
        fill: {
          fgColor: { rgb: '242424' },
        },
      };
    }
  });

  for (let i = 1; i <= excelData.length; i++) {
    const rowColor = i % 2 === 0 ? 'f0fdfa' : 'ffffff';
    for (let j = 0; j < headers.length; j++) {
      const cell = ws[XLSX.utils.encode_cell({ r: i, c: j })];

      cell.s = {
        fill: {
          fgColor: { rgb: rowColor },
        },
        border: {
          top: { style: 'thin', color: { rgb: '000000' } },
          left: { style: 'thin', color: { rgb: '000000' } },
          bottom: { style: 'thin', color: { rgb: '000000' } },
          right: { style: 'thin', color: { rgb: '000000' } },
        },
      };
    }
  }

  const colomSpaceFormat = 6.32688927943761;
  const colomSpace = [28.45, 39.36, 39.36, 21.18, 14.36, 20.18, 13.91, 7.36, 34.18, 13.73, 37, 37.36, 37.73];

  const columnWidths = headers.map((o, i) => ({ wpx: colomSpace.length > i ? colomSpace[i] * colomSpaceFormat : 150 }));
  ws['!cols'] = columnWidths;

  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');

  XLSX.writeFile(wb, 'Colab.xlsx');
}
export { convertToExcel };
