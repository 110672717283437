import React, { useState } from 'react';
import { GetColab } from '../../modules/Colab/types';

type ColabDetailsProps = { colabEntity?: GetColab };

function colabStatusCell({ colabEntity }: ColabDetailsProps) {
  const [selectedOption, setselectedOption] = useState('new');

  // TODO: implement backend status persistence
  function updateColabRecordStatus() {
    return true;
  }

  const handleStatusDropdownChange = async (event: React.ChangeEvent<HTMLSelectElement>) => {
    try {
      const value = event.target.value;
      const response = await updateColabRecordStatus();

      if (response) {
        setselectedOption(value);
      } else {
        console.error('Failed to update colab record status');
      }
    } catch (error) {
      console.error('Error updating colab record status on dropdown change:', error);
    }
  };

  return (
    <div>
      <select
        id={colabEntity?.rowKey}
        className="px-6 py-2 text-sm text-neutral-800 border rounded-md"
        value={selectedOption}
        onChange={handleStatusDropdownChange}>
        <option value="new">New</option>
        <option value="active">Active</option>
        <option value="closed">Closed</option>
      </select>
    </div>
  );
}

export default colabStatusCell;
