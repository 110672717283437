import { User } from '../users/types';

export type Address = {
  longName: string;
  shortName: string;
  types: string[];
};

export type Location = {
  longitude: number;
  latitude: number;
  name: string;
  parkingInfo: string;
  address: Address[];
};

export type Actor = {
  firstName: string;
  profilePicture: string;
  rating: number;
  snowflakeId: string;
  customerNumber: string;
};

export type Price = {
  hourly: number;
  daily: number;
};

export type ElectricStatus = {
  chargeLevel: number;
};

export type Fuel = {
  level: number;
  fuelType: string;
  levelStatus: number;
  electricStatus: ElectricStatus;
};

export type Cost = {
  currency: string;
  base: number;
  fuel: number;
  fuelPerLiter: number;
  insurance: number;
  service: number;
  total: number;
  lenderEarning: number;
  electric: number;
  electricPerKwh: number;
  fuelTypeChargedFor: string;
};

export type Rating = {
  rating: number;
  comment: string;
};

export enum Market {
  Global = '',
  Netherlands = 'NL',
  Belgium = 'BE',
  Germany = 'DE',
  France = 'FR',
  Italy = 'IT',
  Spain = 'ES',
  Sweden = 'SE',
}

export const getCountryByCode = (code: string): string => {
  const countryMapping: Record<string, string> = {
    NL: 'Netherlands',
    BE: 'Belgium',
    DE: 'Germany',
    FR: 'France',
    IT: 'Italy',
    ES: 'Spain',
    SE: 'Sweden',
  };

  return countryMapping[code] || code;
};

export type GetMarketInfo = {
  market: string;
  fuelPerLiter: number;
  fuelPriceEu: number;
  electricPerKwh: number;
  buffer: number;
  currency: string;
  maxCostLimitPerBooking: number;
  vat: number;
  serviceFees: ServiceFee[];
  isChanged: boolean;
};
export type GetEUMarketInfo = {
  country: string;
  euroSuper95: number | undefined;
};
export type EditMarketInfo = {
  fuel: number;
  electricPerKwh: number;
  buffer: number;
  currency: string;
  maxCostLimitPerBooking: number;
  vat: number;
  serviceFees: ServiceFee[];
};

export type ServiceFee = {
  duration: string;
  priceLevel: string;
  fee: number;
};

export type Vehicle = {
  vin: string;
  regNr: string;
  groups: string[];
  equipment: string[];
  price: Price;
  model: string;
  market: Market;
};
const extensionStatuses = ['CANCELLED', 'ACCEPTED', 'REQUESTED', 'REJECTED'] as const;
export type ExtensionStatus = typeof extensionStatuses[number];

export type Extension = {
  endTime: string;
  bookingId?: string;
  status: ExtensionStatus;
};

export enum Status {
  ACCEPTED = 'ACCEPTED',
  ACTIVE_BY_BORROWER = 'ACTIVE_BY_BORROWER',
  ACTIVE_BY_START = 'ACTIVE_BY_START',
  CANCELLED = 'CANCELLED', // Required by endpoint, used only for booking update calls
  CANCELLED_BY_BORROWER = 'CANCELLED_BY_BORROWER',
  CANCELLED_BY_EC = 'CANCELLED_BY_EC',
  CANCELLED_BY_ERROR = 'CANCELLED_BY_ERROR',
  CANCELLED_BY_LENDER = 'CANCELLED_BY_LENDER',
  CANCELLED_BY_PSP = 'CANCELLED_BY_PSP',
  CANCELLED_BY_TIMEOUT = 'CANCELLED_BY_TIMEOUT',
  CLOSED = 'CLOSED',
  FINISHED = 'FINISHED',
  REJECTED = 'REJECTED',
  REQUESTED = 'REQUESTED',
}

export type AddComment = {
  comment: string;
  date: string;
  bookingId: string;
};

export type GetComment = {
  id?: string;
  user?: User;
  comment: string;
  date: string;
  bookingId: string;
};

export type CommentsQuery = {
  bookingId: string;
};

export type GetUsageEvent = {
  event: string;
  createdAt: string;
};

export type GetProvision = {
  state: string;
  createdAt: string;
};

export enum BookingEvent {
  BOOKING_EVENT_UNLOCK = 'Car Unlocked',
  BOOKING_EVENT_LOCK = 'Car Locked',
  BOOKING_EVENT_UNLOCK_TRUNK = 'Trunk Unlocked',
  BOOKING_EVENT_LOCK_TRUNK = 'Trunk Locked',
  BOOKING_EVENT_ENGINE_START = 'Engine Started',
  BOOKING_EVENT_UNKNOWN = 'Unknown Event',
}
export type BookingEventKeys = keyof typeof BookingEvent;

export type Booking = {
  id: string;
  start: string;
  end: string;
  actualEnd: string | null;
  location: Location;
  lender: Actor;
  borrower: Actor;
  vehicle: Vehicle;
  fuel: Fuel;
  cost: Cost;
  rating: Rating;
  status: Status;
  paymentAuthorizationId: string;
  extension: Extension | null;
  digitalKeyId: string;
  odometerKmStart: number;
  odometerKmEnd: number;
  orderReference: string;
  requestedTime: string;
  comments: Comment[];
};

export type BookingsQuery = {
  userId?: string;
  lenderId?: string;
  orderReference?: string;
  vin?: string;
  start?: string;
  end?: string;
  statuses?: Status[];
  market?: Market;
  regNumber?: string;
  bookingId?: string;
  digitalKeyId?: string;
  paymentId?: string;
};

export type BookingRequestErrorData = {
  ErrorCode?: string;
  Message?: string;
  createdAt: string;
  customerNumber: string;
};

export interface Report {
  sendbirdId: string;
  reporting_user?: {
    user_id: string;
  };
  channel: {
    channel_url: string;
    name: string;
  };
  created_at: number;
  report_type: string;
  reviewed: boolean;
}
